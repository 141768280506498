
















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import { getConfigEnv, getComponent, sleep } from '@/utils/helpers';
import DoughnutChart from '@/lib/charts/doughnutChart'

import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
	  TasqItem: () => getComponent('tasqs/TasqItem'),
    DoughnutChart,
  },
})
export default class PadItem extends Vue {
  @Prop({ type: String, required: true }) tasqId?: string;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Object, required: false }) parentTasq?: TasqJob

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

  wellListExpanded = false

  showAllWells = false

  isLoadingTasqRelated = false;

  MAX_WELL_COUNT = 20

  defermentUnits = 'bpd'

  get getTotalPadDeferment() {
	  let total_deferment_count = 0;
	  let localDefermentUnits = 'bpd';
	  for (let x = 0; x < this.getWells.length; x++) {
		  if (this.getWells[x].defermentValue != undefined && !this.getWells[x].completed) {
			  localDefermentUnits = this.getWells[x].defermentUnit;
        total_deferment_count += this.getWells[x].defermentValue;
			  // @ts-ignore
			  total_deferment_count = parseFloat(total_deferment_count);
		  }
	  }
	  this.defermentUnits = localDefermentUnits;
	  total_deferment_count = Math.round(total_deferment_count);
	 if (getConfigEnv('OPERATOR_LOWERCASED') === 'swn' && this.defermentUnits === 'bpd') {
		 return '';
	 }
	  return `-${total_deferment_count.toString()} ${this.defermentUnits} total`;
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['mousemove', 'touchstart'],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 65,
  };

  randomBackground() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [{
      name: 'Tasqs',
      labels: 'Tasqs',
      backgroundColor: ['#5666dd', '#88899f', '#439ee3'],
      hoverBackgroundColor: ['#5666dd', '#88899f', '#439ee3'],
      borderColor: ['#5666dd', '#88899f', '#439ee3'],
      hoverBorderColor: '#FFFFFF',
      hoverBorderWidth: 3,
      borderWidth: 1,
      borderAlign: 'inner',
      data: [3, 5, 6],
    }],
  }

  allWellListToggle() {
	  if (this.showAllWells) {
		  return 'Show producing';
		  }
		  return 'Show producing';
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.allTasqs;
  }

  wellExpandedIcon() {
	  if (this.wellListExpanded) {
		  return 'arrow_drop_down';
	  }
		  return 'arrow_drop_up';
  }

  wellExpandedText() {
	  if (this.wellListExpanded) {
		  return 'Hide tasqs';
	  }
		  return 'Show tasqs';
  }

  async showWellsClicked() {
	  this.wellListExpanded = !this.wellListExpanded;
  }

  get padWells() {
	  // @ts-ignore
	  return this.tasq.wells;
  }

  colors = {
	  workflow: 'rgba(123, 65, 216, 1)',
	  'off-target rt': '#ec3114',
	  'off-target': '#ec8114',
	  'no-comms': '#919191',
	  'no comms': '#919191',
	  producing: '#adadad7e',
	  predictions: '#14A86B',
	  prediction: '#14A86B',
	  failure: '#14A86B',
	  'failure prediction': '#14A86B',
	  anomaly: '#ffb01b',
	  'state change': '#ffb01b',
	  setpoint: '#0076FF',
    'plunger change': 'rgba(0, 118, 255)',
    'standing valve': 'rgba(0, 118, 255)',

  }

  get tasq() {
    if (this.parentTasq) {
      return this.parentTasq;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  fullName() {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // getWells: any = [];

  get producingWells(): TasqJob[] {
    return tasqsListModule.producingWells;
  }

  @Watch('getWells')
  onGetWellsUpdate(data) {
    if (data) {
      console.log('get wells updated');
      this.showStatusChart = false;
      // this.$nextTick(() => {
      const actions = data.reduce((r, a) => {
        // console.log(a.Category);
        if (!a.predictionType || a.predictionType === '') {
          a.predictionType = 'None';
        }
        r[a.predictionType] = r[a.predictionType] || [];
        r[a.predictionType].push(a);
        return r;
      }, Object.create(null));





      if (actions && Object.keys(actions).length) {
        this.isLoadingTasqRelated = true;


         this.$nextTick(() => {
          this.isLoadingTasqRelated = false;
        this.chartData.labels = Object.keys(actions);
        const colors = Object.keys(actions).map((key) => this.colors[key.toLowerCase()] || '#adadad7e');
        const data = Object.keys(actions).map((key) => actions[key].length);
        this.chartData.datasets[0].backgroundColor = colors;
        this.chartData.datasets[0].borderColor = colors;
        this.chartData.datasets[0].hoverBackgroundColor = colors;

        this.chartData.datasets[0].data = data;
        this.showStatusChart = true;

      })
      }
    }
  }

  get getWells() {
    if (this.loadRelatedWells) {
      let tasqList: any = [];

	  const responseData = this.tasqs.filter((well) => well.wellName === this.tasq?.wellName);
	  if (responseData) {
        tasqList = responseData.concat(tasqList);
	  }

      // if (tasqList.length === 0) {
      //   for (let x = 0; x < this.producingWells.length; x++) {
      //     if (this.tasq?.wellName && this.producingWells[x].wellName == this.tasq?.wellName) {
      //       tasqList.push(this.producingWells[x]);
      //     }
      //   }
      // }
      return tasqList;
    }
    return [];
  }

  chartPayload: any = {}

  showStatusChart = false;

  loadRelatedWells= false;

  wellItemTimeout: any = null;


  get isLoadingPaginatedTasqs() {
    return tasqsListModule.isLoadingPaginatedTasqs;
  }

  @Watch('isLoadingPaginatedTasqs')
  async updateTasqsPaginatedValue(value) {
    if (value === false) {
      this.loadRelatedWells = false;
      setTimeout(() => {
        this.loadRelatedWells = true;
      }, 2000);
    }
  }

  async created() {
    if (this.tasq && this.tasq.predictionType !== 'Producing') {
      setTimeout(() => {
        this.loadRelatedWells = true;


      }, 2000);
    }
  }

  goToTasqPage(type) {
    if (type == 'producing') {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.wellName || '',
        },
        query: { type: 'producing', view: 'tasq' },
      });
      tasqsListModule.setActiveTasq(this.tasq?.wellName || '');
      // assetsModule.setActiveProducingTasq(this.tasq)
    } else {
      this.$router.push({
        name: 'Tasqs',
        params: {
          id: this.tasq?.id || '',
        },
        query: { type: 'id', view: 'wellview' },
      });
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    }
  }
}
